import { Immerable, OmitImmerable } from './immerable';

/**
 * Model for the response from create API.
 * @class CreateResponse
 * @augments Immerable
 */
export class CreateResponse extends Immerable {
  public constructor(data: CreateResponseInitArgs) {
    super();
    this.email = data.email;
    this.message = data.message;
    this.sessionId = data.sessionId;
  }

  /**
   * User's sub ID.
   * @type string
   * @memberof IdToken
   */
  public readonly email?: string;

  /**
   * User's sub ID.
   * @type string
   * @memberof IdToken
   */
  public readonly message?: string;

  /**
   * Authenticated user's given name.
   * @type string
   * @memberof IdToken
   */
  public readonly sessionId?: string;
}

type CreateResponseInitArgs = OmitImmerable<CreateResponse>;
