import { createSlice } from '@reduxjs/toolkit';
import { createEmail, getEmail, getEmailList } from './dispatchers';
import { initialState } from './state';

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    clearCurrentSession: state => {
      state.email = undefined;
      state.session = undefined;
      state.emailList = undefined;
      state.emailMessage = undefined;
      state.selectedEmail = undefined;
    },
    setSelectedEmail: (state, action) => {
      state.selectedEmail = action.payload;
    },
  },
  extraReducers: builder => builder
    .addCase(createEmail.pending, state => {
      state.loading = true;
    })
    .addCase(createEmail.fulfilled, (state, action) => {
      state.email = action.payload.email;
      state.session = action.payload.sessionId;
      state.loading = false;
    })
    .addCase(createEmail.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      }
      state.loading = false;
    })
    .addCase(getEmailList.pending, state => {
      state.loading = true;
    })
    .addCase(getEmailList.fulfilled, (state, action) => {
      state.emailList = action.payload;
      state.loading = false;
    })
    .addCase(getEmailList.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      }
      state.loading = false;
    })
    .addCase(getEmail.pending, state => {
      state.loading = true;
    })
    .addCase(getEmail.fulfilled, (state, action) => {
      state.emailMessage = action.payload;
      state.loading = false;
    })
    .addCase(getEmail.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      }
      state.loading = false;
    }),
});

export const { clearCurrentSession, setSelectedEmail } = appSlice.actions;
