import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from '@mui/material';

import { Header } from 'components/Header';
import style from './style.module.css';

export const MainLayout: React.VFC = () => (
  <Suspense fallback={false}>
    <Header />
    <Container className={style.appContainer}>
      <Outlet />
    </Container>
  </Suspense>
);

export default MainLayout;
