/**
 * Extract an error from the API response.
 * @param error List of errors.
 *
 * @returns The first error.
 */
export const extractError = (error: unknown): string | undefined => {
  if (Array.isArray(error)) {
    return error[0];
  }

  return undefined;
};
