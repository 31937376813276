import { AppError } from 'models/app-error';
import { Email } from 'models/email';

/**
 * Nexus state.
 */
export interface AppState {
  /** The current user. */
  readonly email?: string;

  /** The current user. */
  readonly session?: string;

  /** The current user. */
  readonly emailList?: Email[];

  /** The current user. */
  readonly selectedEmail?: Email;

  /** The current user. */
  readonly emailMessage?: string;

  /** Authentication Error. */
  readonly error?: AppError;

  /** Loading Authentication. */
  readonly loading: boolean;
}

export const initialState: AppState = {
  loading: false,
};
