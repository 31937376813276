import { Immerable, OmitImmerable } from './immerable';

/**
 * Model for the response from create API.
 * @class Email
 * @augments Immerable
 */
export class Email extends Immerable {
  public constructor(data: EmailInitArgs) {
    super();
    this.id = data.id;
    this.date = data.date;
    this.isNew = data.isNew;
    this.subject = data.subject;
    this.source = data.source;
    this.destination = data.destination;
  }

  /**
   * User's sub ID.
   * @type string
   * @memberof IdToken
   */
  public readonly id?: string;

  /**
   * Authenticated user's given name.
   * @type string
   * @memberof IdToken
   */
  public readonly date?: string;

  /**
   * Authenticated user's given name.
   * @type string
   * @memberof IdToken
   */
  public readonly isNew?: boolean;

  /**
   * Authenticated user's given name.
   * @type string
   * @memberof IdToken
   */
  public readonly subject?: string;

  /**
   * Authenticated user's given name.
   * @type string
   * @memberof IdToken
   */
  public readonly source?: string;

  /**
   * Authenticated user's given name.
   * @type string
   * @memberof IdToken
   */
  public readonly destination?: string;
}

type EmailInitArgs = OmitImmerable<Email>;

/**
 * Custom Attribute Create.
 */
export interface EmailResponse {
  /** The key. */
  readonly id: string;

  /** The value. */
  readonly date: string;

  /** The value. */
  readonly subject: string;

  /** The value. */
  readonly source: string;

  /** The value. */
  readonly destination: string;
}
