import axios, { AxiosInstance } from 'axios';
import { CONFIG } from 'api/config';
import { CreateResponse } from 'models/create-response';
import { CreateResponseDto } from 'api/dtos/create-response-dto';
import { createResponseMapper } from 'api/mappers/create-response.mappers';
import { Email } from 'models/email';
import { EmailDto } from 'api/dtos/email-dto';
import { emailMapper } from 'api/mappers/email.mappers';

const http: AxiosInstance = axios.create({
  baseURL: CONFIG.appApiDomain,
});

/**
 * Disposable Email API Service namespace.
 * @namespace EmailApiService
 */
export namespace EmailApiService {
  /**
   * Create email address if it doesn't already exist and return session information.
   * @function createEmailAddress
   * @memberof EmailApiService
   * @param email {string} Email address name for new disposable email.
   * @param captcha {string} ReCaptcha v2 validation to validate requests from browser.
   * @returns Response with email address & session data.
   * @throws {Error} Error thrown when the API if unable to complete email/session generation.
   */
  export async function createEmailAddress(email: string, captcha: string): Promise<CreateResponse> {
    const emailAddress = encodeURI(`${email}${CONFIG.appSiteDomain}`);
    const customAttrUrl = `/create?address=${emailAddress}&captcha=${captcha}`;
    const { data } = await http.get<CreateResponseDto>(customAttrUrl);

    return createResponseMapper.fromDto(data);
  }

  /**
   * Fetch the list of emails associated with current email/session.
   * @function fetchEmailList
   * @memberof EmailApiService
   * @param email {string} Email address name for new disposable email.
   * @param session {string} Session UUID to validate the current users details.
   * @returns Custom attribute received from API request as a CustomAttribute.
   * @throws {Error} Error thrown when the API cannot find the `registering_client_id` attribute for the current user.
   */
  export async function fetchEmailList(email: string, session: string): Promise<Email[]> {
    const customAttrUrl = `/${email}?sessionid=${session}`;
    const { data } = await http.get<{
      Items: EmailDto[];
      Count: number;
    }>(customAttrUrl);
    const emailList: Email[] = [];

    await data.Items.forEach(emailDto => {
      emailList.push(emailMapper.fromDto(emailDto));
    });

    return emailList;
  }

  /**
   * Fetch the requested email based on provided UUID.
   * @function getEmailDetails
   * @memberof EmailApiService
   * @param email {string} Email address name for new disposable email.
   * @param session {string} Session UUID to validate the current users details.
   * @param messageId {string} Requested email UUID.
   * @returns Custom attribute received from API request as a CustomAttribute.
   * @throws {Error} Error thrown when the API cannot find the `registering_client_id` attribute for the current user.
   */
  export async function getEmailDetails(email: string, session: string, messageId: string): Promise<string> {
    const { data } = await http.get<string>(`/${email}/${messageId}/?sessionid=${session}`);

    return data;
  }
}
