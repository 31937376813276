import { ValidationErrorDto } from 'api/dtos/validation-error-dto';
import { EntityValidationErrors } from 'models/app-error';
import { extractError } from 'utils/extract-error';
import { Email } from 'models/email';
import { EmailDto } from 'api/dtos/email-dto';
import { IMapperFromDto, IValidationErrorMapper } from './mappers';

/**
 * Custom attribute mapper.
 */
class EmailMapper implements IMapperFromDto<EmailDto, Email>,
  IValidationErrorMapper<EmailDto, Email> {
  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto?: ValidationErrorDto<EmailDto> | null,
  ): EntityValidationErrors<Email> {
    return {
      id: extractError(errorDto?.messageId),
      date: extractError(errorDto?.date),
      isNew: extractError(errorDto?.isNew),
      subject: extractError(errorDto?.subject),
      source: extractError(errorDto?.source),
      destination: extractError(errorDto?.destination),
    };
  }

  /** @inheritdoc */
  public fromDto(dto: EmailDto): Email {
    return new Email({
      id: dto.messageId,
      date: dto.date,
      isNew: Boolean(dto.date),
      subject: dto.subject,
      source: dto.source,
      destination: dto.destination,
    });
  }
}

export const emailMapper = new EmailMapper();
