import { AxiosError } from 'axios';
import { AppError, AppValidationError } from 'models/app-error';
import { ValidationErrorDto } from 'api/dtos/validation-error-dto';
import { IValidationErrorMapper } from './mappers';

interface ServerError {
  /** Global message error. */
  message: string;
  /** List of errors. */
  errors: ValidationErrorDto<unknown>;
}

/**
 * Error mapper type declaration.
 * Could be a simple function to transform errors from DTO to errors of domain model
 * or implementation of IMapper with implemented validationErrorFromDto method.
 */
export type ErrorMapper<TDto, TModel extends object> = IValidationErrorMapper<TDto, TModel> |
IValidationErrorMapper<TDto, TModel>['validationErrorFromDto'];

const NOT_VALIDATION_CODE_ERRORS = [400, 422];

/**
 * App error mapper.
 */
export class AppErrorMapper {
  /**
   * Convert default HttpErrorResponse object to custom application error.
   * @param httpError Http error response.
   */
  public static fromDto(httpError: AxiosError): AppError {
    const { message } = httpError;
    return new AppError(message);
  }

  /**
   * Map HTTP API error response to the appropriate Api error model.
   * @param httpError Http error.
   * @param mapper Mapper function that transform validation DTO errors to the application validation model.
   * @returns AppError if httpError is not "Bad Request" error or AppValidationError if it is "Bad Request"/.
   */
  public static fromDtoWithValidationSupport<TDto, TEntity extends object>(
    httpError: AxiosError,
    mapper: ErrorMapper<TDto, TEntity>,
  ): AppValidationError<TEntity> {
    // This is a validation error => create AppValidationError.
    const errorMessage = (httpError.response?.data as ServerError)?.message;

    if (!NOT_VALIDATION_CODE_ERRORS.includes(httpError.response?.status as number)) {
      // It is not a validation error. Return simple AppError.
      return new AppValidationError<TEntity>(errorMessage, {});
    }

    if (mapper === null) {
      throw new Error('Provide mapper for API errors.');
    }

    if (typeof mapper !== 'function' && mapper.validationErrorFromDto === null) {
      throw new Error('Provided mapper does not have implementation of validationErrorFromDto');
    }

    if ((httpError.response?.data as ServerError).errors === null) {
      return new AppValidationError<TEntity>(errorMessage, null);
    }

    const validationData = typeof mapper === 'function'
      ? mapper((httpError.response?.data as ServerError).errors)
      : mapper.validationErrorFromDto((httpError.response?.data as ServerError).errors);
    return new AppValidationError<TEntity>(errorMessage, validationData);
  }
}
