import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { RootRouter } from './routes/RootRouter';
import { AppProvidersWrapper } from './AppProvidersWrapper';
import { store } from './store';

export const App = () => (
  <Provider store={store}>
    <AppProvidersWrapper>
      <BrowserRouter>
        <RootRouter />
      </BrowserRouter>
    </AppProvidersWrapper>
  </Provider>
);
