import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const InboxPage = lazy(() => import('./pages').then(module => ({ default: module.InboxPage })));

export const inboxRoutes: RouteObject[] = [
  {
    path: 'inbox',
    element: <InboxPage />,
  },
];
