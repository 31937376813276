import { CreateResponseDto } from 'api/dtos/create-response-dto';
import { ValidationErrorDto } from 'api/dtos/validation-error-dto';
import { CreateResponse } from 'models/create-response';
import { EntityValidationErrors } from 'models/app-error';
import { extractError } from 'utils/extract-error';
import { IMapperFromDto, IValidationErrorMapper } from './mappers';

/**
 * Custom attribute mapper.
 */
class CreateResponseMapper implements IMapperFromDto<CreateResponseDto, CreateResponse>,
  IValidationErrorMapper<CreateResponseDto, CreateResponse> {
  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto?: ValidationErrorDto<CreateResponseDto> | null,
  ): EntityValidationErrors<CreateResponse> {
    return {
      email: extractError(errorDto?.email),
      message: extractError(errorDto?.message),
      sessionId: extractError(errorDto?.sessionid),
    };
  }

  /** @inheritdoc */
  public fromDto(dto: CreateResponseDto): CreateResponse {
    return new CreateResponse({
      email: dto.email,
      message: dto.message,
      sessionId: dto.sessionid,
    });
  }
}

export const createResponseMapper = new CreateResponseMapper();
