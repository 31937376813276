import { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {
  AppBar, IconButton, Link, Toolbar, Tooltip, Typography,
} from '@mui/material';

import { useAppDispatch, useAppSelector } from 'store';
import { getEmailList } from 'store/app/dispatchers';
import { clearCurrentSession } from 'store/app/slice';
import {
  selectCurrentEmailAddress, selectCurrentSession,
} from 'store/app/selectors';

import style from './style.module.css';

const HeaderComponent: React.VFC = () => {
  const dispatch = useAppDispatch();
  const session = useAppSelector(selectCurrentSession);
  const email = useAppSelector(selectCurrentEmailAddress);

  const logout = () => {
    dispatch(clearCurrentSession());
  };

  const refresh = () => {
    if (email && session) {
      dispatch(getEmailList({ email, session }));
    } else {
      logout();
    }
  };

  return (
    <AppBar position="fixed">
      <Toolbar className={style.headerHolder}>
        <Link
          className={style.headerLink}
          component={RouterLink}
          to="/"
          variant="h5"
          noWrap
        >
          <Typography>
            Winegard Disposable Email
          </Typography>
        </Link>

        {email && (
          <>
            <Typography>
              Email address:
              {' '}
              <b>{email}</b>
            </Typography>

            <div>
              <Tooltip title="Refresh list">
                <IconButton color="inherit" onClick={refresh}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Logout">
                <IconButton color="inherit" onClick={logout}>
                  <ExitToAppIcon />
                </IconButton>
              </Tooltip>
            </div>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export const Header = memo(HeaderComponent);
