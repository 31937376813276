import { createAsyncThunk } from '@reduxjs/toolkit';
import { EmailApiService } from 'api/services/email-api';
import { isApiError } from 'utils/axios-error-guard';
import { CreateResponse } from 'models/create-response';
import { AppError, AppValidationError } from 'models/app-error';
import { AppErrorMapper } from 'api/mappers/app-error.mapper';
import { createResponseMapper } from 'api/mappers/create-response.mappers';
import { Email } from 'models/email';
import { emailMapper } from 'api/mappers/email.mappers';

export const createEmail = createAsyncThunk<
CreateResponse,
{ email: string; captcha: string; },
{ rejectValue: AppValidationError<CreateResponse>; }
>('app/createEmail', async ({ email, captcha }, { rejectWithValue }) => {
  try {
    return await EmailApiService.createEmailAddress(email, captcha);
  } catch (error: unknown) {
    if (isApiError(error)) {
      const validationErrors = AppErrorMapper.fromDtoWithValidationSupport(error, createResponseMapper);

      return rejectWithValue(validationErrors);
    }

    throw error;
  }
});

export const getEmailList = createAsyncThunk<
Email[],
{ email: string; session: string; },
{ rejectValue: AppValidationError<CreateResponse>; }
>('app/getEmailList', async ({ email, session }, { rejectWithValue }) => {
  try {
    return await EmailApiService.fetchEmailList(email, session);
  } catch (error: unknown) {
    if (isApiError(error)) {
      const validationErrors = AppErrorMapper.fromDtoWithValidationSupport(error, emailMapper);

      return rejectWithValue(validationErrors);
    }

    throw error;
  }
});

export const getEmail = createAsyncThunk<
string,
{ email: string; session: string; message: string; },
{ rejectValue: AppError; }
>('app/getEmail', async ({ email, session, message }) => {
  try {
    return await EmailApiService.getEmailDetails(email, session, message);
  } catch (error: unknown) {
    throw new AppError(`Unable to retrieve email details for ${message}`);
  }
});
