import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';

export const selectCurrentEmailAddress = createSelector(
  (state: RootState) => state.app.email,
  configuration => configuration,
);

export const selectCurrentEmailMessage = createSelector(
  (state: RootState) => state.app.emailMessage,
  configuration => configuration,
);

export const selectCurrentSession = createSelector(
  (state: RootState) => state.app.session,
  configuration => configuration,
);

export const selectCurrentEmailList = createSelector(
  (state: RootState) => state.app.emailList,
  configuration => configuration,
);

export const selectSelectedEmail = createSelector(
  (state: RootState) => state.app.selectedEmail,
  configuration => configuration,
);
