import { memo } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import {
  CssBaseline, StyledEngineProvider,
} from '@mui/material';

interface AppProvidersWrapperProps {
  /** Children. */
  children: React.ReactNode;
}

const AppProvidersWrapperComponent: React.VFC<AppProvidersWrapperProps> = ({ children }) => (
  <StyledEngineProvider injectFirst>
    <CssBaseline />
    <HelmetProvider>
      {children}
    </HelmetProvider>
  </StyledEngineProvider>
);

export const AppProvidersWrapper = memo(AppProvidersWrapperComponent);
