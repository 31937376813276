import React from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import { loginRoutes } from 'features/login/routes';
import { inboxRoutes } from 'features/inbox/routes';
import { MainLayout } from 'layout/MainLayout';

const routes: RouteObject[] = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      ...loginRoutes,
      ...inboxRoutes,
    ],
  },
];

export const RootRouter: React.VFC = () => useRoutes(routes);
